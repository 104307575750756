<mbu-header-message *ngIf="showAlphaModuleWarning" [level]="'warning'"
                    [message]="'Dieses Modul ist in der Entwicklungsphase und sollte <strong>nicht produktiv</strong> eingesetzt werden.'"></mbu-header-message>
<mbu-header-message *ngIf="isOffline" [hideIcon]="true" [level]="'error'"
                    [message]="'Sie sind offline. Bitte stellen Sie sicher, dass sie eine aktive Internetverbindung haben.'"></mbu-header-message>
<mbu-header-message *ngIf="appUpdateAvailable" [level]="'info'" [message]="'Es ist ein Update verfügbar. <strong>Klicken Sie hier um jetzt zu aktualisieren</strong>!'" [clickFn]="reloadPage"></mbu-header-message>
<mbu-header-message *ngIf="showDemoMessage" [level]="'warning'"
                    [message]="'Dies ist ein <strong>Demo-System!</strong> Bitte geben Sie keine Daten ein, welche auf Dauer zur Verfügung stehen sollen.'"></mbu-header-message>
<mbu-header-message *ngIf="updateInformation && updateInformation.startsToday" [level]="updateInformation.level" [message]="updateInformation.getMessage()" [hidePrefix]="true"></mbu-header-message>
<mbu-header *ngIf="isLoggedIn" class="app-header" [title]="appTitle"></mbu-header>

<div class="app-container" [ngClass]="{'sidebar-open':checkSidebarIsOpen()}">
  <div class="app-notification-container" *ngIf="isLoggedIn">
    <ng-container *ngFor="let notification of notifications">
      <msg-notification (killed)="notification['killed'] = true" [notification]="notification" *ngIf="!notification['killed']"></msg-notification>
    </ng-container>
  </div>
  <div class="app-menu" *ngIf="isLoggedIn">
    <mbu-navigation></mbu-navigation>
  </div>
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="app-sidebar float" [ngClass]="{'open': checkSidebarIsOpen()}" *ngIf="isLoggedIn">
  <mbu-sidebar></mbu-sidebar>
</div>

<mbu-footer class="app-footer" *ngIf="isLoggedIn"></mbu-footer>
<router-outlet name="error"></router-outlet>
<router-outlet name="navigator"></router-outlet>
<router-outlet name="settings"></router-outlet>
<router-outlet name="help"></router-outlet>
<router-outlet name="downloadOptions"></router-outlet>
<router-outlet name="detailsOutlet"></router-outlet>
<router-outlet name="modal"></router-outlet>


<div class="blocker" *ngIf="checkSidebarIsOpen()" (click)="toggleSidebar()"></div>
