import { Routes } from '@angular/router';
import { AuthGuard, ModuleGuard, TokenGuard } from '@marketingbox/account';
import { ComForgotPasswordComponent, ComLoginComponent, ComLogoutComponent } from '@marketingbox/common';
import { MbuAboutComponent, MbuNoPermissionComponent, MbuPageNotFoundComponent } from '@marketingbox/ui';

/**
 * Class for the complete navigation inside the application
 */
export class ApplicationRoutes {
  /**
   * List of main routes for the complete application
   * @type Routes
   */
  static routes: Routes = [
    /**
     * Default-Route to redirect to the dashboard
     */
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
      data: {}
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      // loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Dashboard',
        icon: 'fa-home',
        permission: true,
        showInNavigation: true
      }
    },

    {
      path: 'planer',
      loadChildren: () => import('./planer/planer.module').then(m => m.PlanerModule),
      // loadChildren: () => import('./planer/planer.module').then(mod => mod.PlanerModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Planer',
        icon: 'fa-calendar-alt',
        permission: true,
        showInNavigation: true
      }
    },
    {
      path: 'knowledgedb',
      loadChildren: () => import('./knowledgedb/knowledgedb.module').then(m => m.KnowledgedbModule),
      // loadChildren: () => import('./knowledgedb/knowledgedb.module').then(mod => mod.KnowledgedbModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Wissensdatenbank',
        icon: 'fa-head-side-brain',
        permission: true,
        showInNavigation: true
      }
    },
    {
      path: 'contentdb',
      loadChildren: () => import('./contentdb/contentdb.module').then(m => m.ContentdbModule),
      // loadChildren: () => import('./contentdb/contentdb.module').then(mod => mod.ContentdbModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Contentdatenbank',
        icon: 'fa-box-full',
        permission: true,
        showInNavigation: true
      }
    },
    {
      path: 'favourites',
      loadChildren: () => import('./favourites/favourites.module').then(m => m.FavouritesModule),
      // loadChildren: () => import('./favourites/favourites.module').then(mod => mod.FavouritesModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Favoriten',
        icon: 'fa-heart',
        permission: true,
        showInNavigation: true
      }
    },
    {
      path: 'administration',
      loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
      // loadChildren: () => import('./administration/administration.module').then(mod => mod.AdministrationModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Administration',
        icon: 'fa-cogs',
        permission: true,
        showInNavigation: true,
      }
    },

    /**
     * Navigate to the About-Page
     */
    {
      path: 'about',
      component: MbuAboutComponent,
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Über die Anwendung',
        icon: 'fa-info-circle',
        permission: true,
        showInNavigation: false
      }
    },

    /**
     * The system-login-route
     */
    {
      path: 'login',
      component: ComLoginComponent,
      data: {
        permission: true,
        showInNavigation: false,
        label: 'Login'
      }
    },
    /**
     * The system-logout-route
     */
    {
      path: 'logout',
      component: ComLogoutComponent,
      data: {
        permission: true,
        showInNavigation: false,
        label: 'Logout'
      }
    },
    /**
     * The system-forgot-password-route
     */
    {
      path: 'forgot-password',
      component: ComForgotPasswordComponent,
      data: {
        permission: true,
        showInNavigation: false,
        label: 'Passwort vergessen'
      }
    },
    {
      path: 'support',
      loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
      // loadChildren: () => import('./support/support.module').then(mod => mod.SupportModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Support',
        icon: 'fa-question-circle',
        permission: true,
        showInNavigation: true,
        placement: 'bottom'
      }
    },

    {
      path: 'legal',
      loadChildren: () => import('./zzz_lazy/legal.module').then(m => m.LegalModule),
      canActivate: [
        TokenGuard,
        AuthGuard,
        ModuleGuard
      ],
      data: {
        label: 'Rechtliches',
        icon: 'fa-info-circle',
        permission: true,
        showInNavigation: true,
        placement: 'bottom'
      }
    },

    /**
     * No-Permission-Route, redirected from Guard
     */
    {
      path: 'yunopermission',
      component: MbuNoPermissionComponent,
      data: {
        permission: true,
        showInNavigation: false
      }
    },

    /**
     * No page found, just show the user
     */
    {
      path: 'pagenotfound',
      component: MbuPageNotFoundComponent,
      data: {
        permission: true,
        showInNavigation: false,
        label: '404 Seite nicht gefunden'
      }
    },

    /**
     * If path was not found before, redirect to pagenotfound
     */
    {
      path: '**',
      redirectTo: 'pagenotfound',
      data: {
        showInNavigation: false
      }
    }
  ];
}
