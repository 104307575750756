import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserConfigService } from '@marketingbox/account';
import { ProceedAfterLoginInterface } from '@marketingbox/common';
import { NotificationMessageService } from '@marketingbox/messages';
import { DownloadNotificationService } from '@marketingbox/download';
import { SocketService } from '@marketingbox/socket';

/**
 * Just a demo comment
 */
@Injectable()
export class AppProceedAfterLoginService implements ProceedAfterLoginInterface {

  /**
   * Just a demo comment
   */
  constructor(private notificationService: NotificationMessageService,
              private downloadNotificationService: DownloadNotificationService,
              private socketService: SocketService,
              private userConfigService: UserConfigService,
              private router: Router) {
  }

  /**
   * Init services after login
   */
  private _initAll() {
    this.setDefaultApplicationSettings();
    this.notificationService.setToken();
    this.downloadNotificationService.setToken();
  }

  /**
   * Set the defaults for all application-settings in case they are not set yet
   */
  setDefaultApplicationSettings(): void {
    this.userConfigService.setToken();
  }

  /**
   * Redirects the frontend with the help of the given params. Also splits possible route-parameters
   * @param b64String List of parameters the components gets via activatedRoute
   */
  proceed(b64String: string) {
    this._initAll();

    const parameters = {};
    const pathData = atob(b64String).split(';');
    const path = pathData.shift();

    if (path.indexOf('/sixcms/') === 0) {
      window.location.href = path;
      return;
    }

    (pathData || []).forEach(set => {
      const setSplit = set.split('=');
      parameters[setSplit[0]] = setSplit[1];
    });
    if (Object.keys(parameters).length <= 0) {
      this.router.navigate([path]);
      return;
    }

    this.router.navigate([
      path,
      parameters
    ]);
  }
}
