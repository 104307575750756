/**
 * Configuration for the shape-shift service and its localStorage-keys
 * @todo Try to implement abstract class, due to static-interface "bug?"
 * See: [stackoverflow.com]{@link https://stackoverflow.com/questions/13955157/how-to-define-static-property-in-typescript-interface}
 */
export class ShapeShiftConfig {
  /**
   * LocalStorage key for the active shape-shift companyId
   * @type {string}
   */
  static activeIdKey = 'company.shape-shift.active.id';

  /**
   * LocalStorage key for the active shape-shift companyName
   * @type {string}
   */
  static activeNameKey = 'company.shape-shift.active.name';

  /**
   * LocalStorage key for a list of last used companies
   * @deprecated 2.8.0
   * @type {string}
   */
  static latestKey = 'company.shape-shift.latest';
}
