/**
 * Default-config for the MarketingBOX-Datepicker
 */
export const DatepickerConfig = {
  alignSelectorRight: false,
  openSelectorTopOfInput: false,
  showSelectorArrow: false,
  editableDateField: false,
  openSelectorOnInputClick: true,
  dateFormat: 'dd.mm.yyyy',
  todayBtnTxt: 'Heute',
  firstDayOfWeek: 'mo',
  sunHighlight: true,
  // selectorHeight: '300px',
  // selectorWidth: '300px',
  dayLabels: {
    su: 'So',
    mo: 'Mo',
    tu: 'Di',
    we: 'Mi',
    th: 'Do',
    fr: 'Fr',
    sa: 'Sa'
  },
  monthLabels: {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mär',
    '4': 'Apr',
    '5': 'Mai',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Okt',
    '11': 'Nov',
    '12': 'Dez'
  }
};
