import { Component, ErrorHandler } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { branch, tag, time } from '@app/build';
import { name, version } from '@app/package';
import { LoginService, ShapeSessionService, UserConfigService, UserSessionService } from '@marketingbox/account';
import { Notification, NotificationMessageService, UpdateInfo } from '@marketingbox/messages';
import { SocketService } from '@marketingbox/socket';
import { LocalStorageService } from '@marketingbox/storage';
import { SidebarService } from '@marketingbox/ui';
import { OfflineService } from '@marketingbox/utils';
import * as moment from 'moment';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';

/**
 * Just a demo comment
 */
@Component({
  selector: 'application-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * Just a demo comment
   */
  isLoggedIn: boolean;

  /**
   * Title that will be shown in the headline
   */
  appTitle: string;

  /**
   * True, if application is offline
   */
  isOffline: boolean;

  /**
   * List of actual notifications that are active
   */
  notifications: Notification[];

  /**
   * True, if application is in production-use
   */
  showDemoMessage: boolean;

  /**
   * Holds the information for the future update
   */
  updateInformation: UpdateInfo;

  /**
   * True if an update for the frontend ist available
   */
  appUpdateAvailable: boolean;

  /**
   * If true, shows a warning for the user, that he is using an alpha-module
   */
  showAlphaModuleWarning: boolean;

  /**
   * Load needed services
   * @param errorService
   * @param loginService
   * @param activatedRoute
   * @param titleService
   * @param socketService
   * @param userSessionService
   * @param shapeSessionService
   * @param notificationMessageService
   * @param userConfigService
   * @param offlineService
   * @param localStorageService
   * @param router
   * @param sidebarService
   * @param swUpdate
   */
  constructor(private errorService: ErrorHandler,
              private loginService: LoginService,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private socketService: SocketService,
              private userSessionService: UserSessionService,
              private shapeSessionService: ShapeSessionService,
              private notificationMessageService: NotificationMessageService,
              private userConfigService: UserConfigService,
              private offlineService: OfflineService,
              private localStorageService: LocalStorageService,
              private router: Router,
              private sidebarService: SidebarService,
              private swUpdate: SwUpdate) {
  }

  /**
   * Start listening to marketingbox.update and save the information
   * @private
   */
  private _registerUpdateInformation() {
    this.socketService.on('marketingbox.update', '_all').subscribe(data => {
      if (data) {
        const d = JSON.parse(data);
        if (this.updateInformation) {
          this.updateInformation.disabled = true;
        }
        this.updateInformation = new UpdateInfo(d.start, d.end, d.reason);
      }
    });
  }

  /**
   * Just a demo comment
   */
  private _registerToServiceWorkerMessages() {
    if ('serviceWorker' in navigator && environment.production) {
      this.swUpdate.available.subscribe(evt => {
        this.appUpdateAvailable = true;
      });

      this.swUpdate.checkForUpdate();
    }
  }

  /**
   * Just a demo comment
   */
  private _setApplicationInformation() {
    this.localStorageService.set('appBuildTag', tag);
    this.localStorageService.set('appBuildBranch', branch);
    this.localStorageService.set('appBuildTime', moment(time, 'YYYY-MM-DD:HH:mm:ss').format('DD.MM.YYYY'));
    this.localStorageService.set('appVersion', version);
    this.localStorageService.set('appName', name);
  }

  /**
   * Reload the page … simple af
   */
  reloadPage() {
    window.location.reload();
  }

  /**
   * Checks, if the app-sidebar is open or closed
   * @returns {boolean}
   */
  checkSidebarIsOpen(): boolean {
    return this.sidebarService.open;
  }

  /**
   * Toggle the open-state of the application-sidebar
   * @returns {boolean}
   */
  toggleSidebar(): boolean {
    return this.sidebarService.toggleOpen();
  }

  /**
   * Subscribe the error-service handler and react to changes
   */
  registerErrorService(): void {
    // Subscribes for error-service changes
    const sub = this.errorService['changes'].subscribe(error => {
      if (Object.keys(error).length > 0) {
        // it is very important to unsubscribe, because in some cases it would be a damn infinite-loop!
        sub.unsubscribe();
        this.router.navigate([{outlets: {error: ['error']}}]);
      }
    }, err => console.error(err));
  }

  /**
   * Listen to the router-events
   */
  registerRouterEvents(): void {
    let activateExperimentalBreadcrumb = false;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary')
    ).subscribe((event) => {
      this.showAlphaModuleWarning = false;
      let snap = event.snapshot;
      const paths = [];

      while (snap.parent) {
        if (snap.data.label) {
          if (snap.parent.data.label !== snap.data.label) {
            paths.push(snap.data);
          }
        }
        if (snap.data.showAlphaModuleWarning) {
          this.showAlphaModuleWarning = true;
        }
        if (snap.data.activateExperimentalBreadcrumb) {
          activateExperimentalBreadcrumb = true;
        }
        if (snap.parent) {
          snap = snap.parent;
        }
      }

      this.appTitle = '';
      paths.reverse();

      if (paths.length > 0) {
        const title = paths[0].label;

        this.appTitle = `${title}`;
        this.titleService.setTitle(`${title}`);
        if (activateExperimentalBreadcrumb) {
          const breadcrumb = paths.map(path => path.label).join(' / ');
          this.appTitle = breadcrumb;
          this.titleService.setTitle(breadcrumb);
        } else {
          this.appTitle = `${title}`;
          this.titleService.setTitle(`${title}`);
        }
      }
    });
  }

  /**
   * Registers a subscriber to add new notifications to the {@see notifications}-Array
   */
  registerNotifications(): void {
    this.notifications = [];
    this.notificationMessageService.newNotification.subscribe(data => {
      if (!data) {
        return;
      }
      const notification = data;
      notification['killed'] = false;
      this.notifications.push(notification);
      this.notifications.sort((a: Notification, b: Notification) => {
        return a.timestamp.format('x') > b.timestamp.format('x') ? -1 : 1;
      });
    });
  }

  /**
   * Just a demo comment
   */
  registerOfflineService() {
    this.offlineService.on.subscribe(offline => {
      this.isOffline = offline;
    });
    this.isOffline = this.offlineService.isOffline;
  }

  /**
   * Initializes the component and adds a route-subscriber for scrolling up on route change and registers a error-service
   */
  ngOnInit(): void {
    this.registerErrorService();
    this.registerRouterEvents();
    this.registerNotifications();
    this.registerOfflineService();
    this._registerUpdateInformation();

    this._registerToServiceWorkerMessages();

    this.showDemoMessage = environment.settings.flags.demo;

    this._setApplicationInformation();
    this.loginService.status.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn === 'loggedIn';
    });
  }
}
