import { Environment } from './environment.class';

/**
 * Environment for the stage-server
 * @type {Environment}
 */
export const defaultStageEnvironment: Environment = {
  production: true,
  settings: {
    flags: {
      demo: false
    }
  },
  ext: {
    dashboard: 'https://stage.marketingbox.de/sixcms/detail.php/vme_navi_uebersicht',
    login: 'https://stage.marketingbox.de/sixcms/detail.php/login',
    brochure_ext_info_url: 'https://stage.marketingbox.de/sixcms/detail.php/vme_erstinfo_details?content_id=_ID_&erstinfo_id=_ID_&type=erstinfo',
    product_ext_info_url: 'https://stage.marketingbox.de/sixcms/detail.php/vme_navi_av_teil?type=teil&cos=true&content_id=_ID_',
    productSet_ext_info_url: 'https://stage.marketingbox.de/sixcms/detail.php/vme_navi_av_m_allgemein?type=milieu&content_id=_ID_',
    profile: 'https://stage.marketingbox.de/sixcms/detail.php/vme_userprofile_edit',
    pim_ext_id_search: 'https://stage.marketingbox.de/sixcms/admin/content/?id=_ID_&quicksearch[type]=id&quicksearch[text]=_ID_'

  },
  api: {
    base: 'https://v1-api.stage.marketingbox.de'
  },
  download: {
    url: 'https://download.stage.marketingbox.de'
  },
  sockets: {
    port: 443,
    url: 'sockets.stage.marketingbox.de',
    protocol: 'https://'
  }
};
