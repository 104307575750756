export const SupportConfig = {
  tel: {
    display: '+49 (0) 2561-9830-333',
    link: '+4925619830333'
  },
  mail: {
    display: 'vme@support.marketingbox.de',
    link: 'vme@support.marketingbox.de'
  }
};
