import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { branch, tag, time } from '@app/build';
import { version } from '@app/package';
import { SupportConfig } from '@app/support';
import { LoginService, MarketingboxAccountModule, UserConfigService } from '@marketingbox/account';
import { MarketingboxCommonModule, ProceedAfterLoginService } from '@marketingbox/common';
import { MarketingboxDownloadModule } from '@marketingbox/download';
import { GlobalErrorService, MarketingboxErrorModule } from '@marketingbox/error';
import { MarketingboxHttpModule } from '@marketingbox/http';
import { MarketingboxMessagesModule, NotificationMessageService } from '@marketingbox/messages';
import { ConfigService as SearchConfigService, SearchQueryProviderService } from '@marketingbox/search';
import { MarketingboxSocketModule } from '@marketingbox/socket';
import { MarketingboxStorageModule } from '@marketingbox/storage';
import { ConfigService as UiConfigService, MarketingboxUiModule } from '@marketingbox/ui';
import { OfflineService } from '@marketingbox/utils';
import { DatepickerConfig } from '../../../marketingbox/src/app/_configs/app/datepickerConfig';

import { ShapeShiftConfig } from '../../../marketingbox/src/app/_configs/app/shape-shift.config';
import { environment } from '../environments/environment';
import { AppProceedAfterLoginService } from './app-proceed-after-login.service';
import { ApplicationRoutes } from './app-routes';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/**
 * Just a demo comment
 */
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production && 'serviceWorker' in navigator}),
    MarketingboxErrorModule,
    MarketingboxStorageModule,
    MarketingboxMessagesModule,
    MarketingboxCommonModule.forRoot({
      login: {
        welcome: {
          bold: 'Herzlich Willkommen',
          normal: 'im MarketingPORTAL'
        }
      },
      support: SupportConfig
    }),
    MarketingboxSocketModule.forRoot(environment.sockets),
    MarketingboxAccountModule.forRoot(ShapeShiftConfig),
    MarketingboxHttpModule.forRoot({companyShapeShiftActiveId: ShapeShiftConfig.activeIdKey, host: environment.api.base}),
    MarketingboxDownloadModule.forRoot({downloadUrl: environment.download.url}),
    MarketingboxUiModule.forRoot({
      datepicker: DatepickerConfig, // datepickerConfig,
      searchTypeNames: {
        radio: 'radio', checkbox: 'checkbox', range: 'range', date: 'date', boolean: 'boolean'
      }, // SearchTypeNamesConfig,
      settings: [], // ApplicationSettingsService.settings,
      routes: ApplicationRoutes.routes,
      build: {branch: branch, tag: tag, time: time},
      package: {version: version}
    })
  ],
  providers: [
    LoginService,
    NotificationMessageService,
    SearchQueryProviderService,
    OfflineService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorService
    },
    {
      provide: ProceedAfterLoginService,
      useClass: AppProceedAfterLoginService
    },
    {
      provide: SearchConfigService,
      useExisting: UserConfigService
    },
    {
      provide: UiConfigService,
      useExisting: UserConfigService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
