import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationRoutes } from './app-routes';

/**
 * Just a demo comment
 */
const routes: Routes = ApplicationRoutes.routes;

/**
 * Just a demo comment
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
